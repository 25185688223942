import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Container from '../components/Container'
import Article from '../components/Article'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import MetaTag from '../components/MetaTag'

const Aboutus = () => {
    return (
        <div className='bg-[#E4E4E4] min-h-screen'>
            <Layout>
                <Article title="about us" color="[#626262]">
                    <Container>
                        <div className='md:flex md:inline-block items-center'>
                            <div className='md:w-3/5'>
                                <div className='flex flex-col items-center'>
                                    <p className='fullhd:text-3xl 2xl:text-2xl xl:text-xl text-base 
                                                    xsm:text-sm text-center'>
                                                    自分自身にとって、最適な一つの選択
                                    </p>
                                    <p className='fullhd:text-4xl 2xl:text-3xl xl:text-2xl text-xl 
                                                    xsm:text-base text-center 2xl:mt-8 mt-4'>
                                        <span className='font-bold'>One Select</span> × <span className='font-bold'>Oneself</span>
                                    </p>
                                    <ChevronDownIcon className='text-black my-8' width={24} />
                                    <StaticImage src='../images/Headers/onesel_logo_fix.png' alt='onesel_logo' className='max-w-[640px] md:w-[25vw] sm:w-[45vw] w-[60vw]'/>
                                    <p className='tracking-widest fullhd:text-3xl 2xl:text-2xl xl:text-xl text-base 
                                                    xsm:text-sm text-center mt-4'>
                                                    【ワンセル】
                                    </p>
                                </div>
                                <div class="divider mt-8 mb-4"></div> 
                                <p className='md:text-center xl:text-xl xl:leading-loose lg:text-base lg:leading-loose md:text-sm md:leading-loose text-xs leading-loose mb-8'>
                                    <span className='xsm:inline-block'>いま我々が手にしているモノ・享受しているサービスは、</span><br />
                                    <span className='xsm:inline-block'>これまで培っていた経験や集約した情報から選択された一つの回答です。</span><br />
                                    <span className='xsm:inline-block'>しかしそれは、常に良い選択だったわけではなかったはず。</span><br /><br />
                                    <span className='xsm:inline-block'>新しいジャンルにチャレンジしたり、未知の領域に踏み込んでみたり、</span><br />
                                    <span className='xsm:inline-block'>これからも新たな選択をし続けていく皆さんの道しるべとなる企業でありたい。</span><br />
                                    <span className='xsm:inline-block'>その選択が心から良かったと思わせるサービスでありたい。</span><br />
                                    <span className='xsm:inline-block'>ONESELという名称には、そんな意味が込められています。</span>
                                </p>
                            </div>
                            <div className='md:inline-block md:w-2/5 text-right xmd:flex xmd:justify-end'>
                                <StaticImage src='../images/about_image.png' alt='about_image' className='max-w-[432px] xl:h-[25vh] xl:w-[25vh] h-[250px] w-[250px]'/>
                            </div>
                        </div>
                    </Container>
                </Article>
            </Layout>
      </div> 
    )
}

export default Aboutus

export function Head({ location }) {
    return (
      <MetaTag
        pathname={location.pathname}
        title='about us | ONESEL'
      >
      </MetaTag>
    )
  }